<template>
  <div>
    <div class="content">
      <el-form
        :label-position="'top'"
        label-width="80px"
        :model="resetForm"
        ref="resetForm"
        :rules="resetRules"
      >
        <el-form-item prop="password">
          <el-input
            v-model="resetForm.password"
            :placeholder="$t('wrongPasswordFormat')"
            type="password"
            size="medium"
            :show-password="true"
            nowritten
          ></el-input>
        </el-form-item>
        <el-form-item prop="passwordAgain">
          <el-input
            v-model="resetForm.passwordAgain"
            nowritten
            type="password"
            :placeholder="$t('enterPassword')"
            size="medium"
            :show-password="true"
          ></el-input>
        </el-form-item>
        <div class="button_block" @click="reset">
          <slot name="footerBtn"></slot>
          <!-- <el-button class="phoneButton" @click="reset" :loading="loading"
            >确认</el-button
          > -->
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { validatePassWord } from "~/baseUtils/validate";
import { SSstore } from "~/baseUtils/storage";
import { constName } from "~/baseUtils/constName";
import { _decodeString } from "~/basePlugins/base64";
export default {
  data() {
    return {
      resetForm: {
        password: "",
        passwordAgain: "",
      },
      loading: false,
      resetRules: {
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validatePassWord(value)) {
              callback(new Error(this.$t("wrongPasswordFormat")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        passwordAgain: {
          validator: (rule, value, callback) => {
            if (this.resetForm.password == "") {
              callback();
            } else if (this.resetForm.password != "" && value == "") {
              callback(new Error(" "));
            } else if (
              this.resetForm.password != "" &&
              value !== this.resetForm.password
            ) {
              callback(new Error(this.$t("passwordMatch")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  async mounted() {
    if (this.$route.query.parameter) {
      let params;
      params = _decodeString(this.$route.query.parameter);
      if (params.user_id && params.email) {
        try {
          let result = await this.$store.dispatch("baseStore/baseSign_Verify", {
            type: params.type,
            email: params.email,
            code: params.code,
            source: params.source,
            user_id: params.user_id,
          });
          if (result.success) {
            // return { user_id: params.user_id };
            this.user_id = params.user_id;
          }
        } catch (e) {
          //   return { user_id: "" };
          this.user_id = "";
        }
      }
    } else {
        this.user_id = SSstore.get("GLOBALRESETUID");
        if (!this.user_id) {
          this.$router.go(-1);
        } else {
          SSstore.remove("GLOBALRESETUID");
        }
    }
  },
  methods: {
    reset() {
      this.$refs.resetForm.validate(async (vaild) => {
        if (vaild) {
          this.$emit("getLoading", true);
          let result = await this.$store.dispatch(
            "baseStore/baseSign_resetPassword",
            {
              user_id: this.user_id,
              password: this.resetForm.password,
            }
          );
          if (result.success) {
            this.$emit("successChange");
            this.step = 1;
          } else {
            this.$seTip();
          }
          this.$emit("getLoading", false);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>