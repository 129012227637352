<template>
  <div class="resetPassword">
    <div class="page">
      <div v-if="!isStep">
        <div class="tc title">
          <i class="el-icon-back iconBack cursor" @click="back"></i
          >{{ $t("resetPassword") }}
        </div>
        <resetPassword
          @successChange="resetChange"
          @getLoading="btnLoadingChange"
        >
          <el-button
            slot="footerBtn"
            type="primary"
            class="loginBut"
            size="small"
            :loading="btnLoading"
          >
            {{ $t("confirm") }}</el-button
          >
        </resetPassword>
      </div>
      <div v-else>
        <div class="resetTips tc">
          <img :src="successImg" alt="" />
          <p>{{ $t("passwordResetSuccess") }}</p>
          <router-link to="/sign">{{ $t("emailAladyTip2") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resetPassword from "~/baseComponents/baseSign/resetPassword";

export default {
  components: {
    resetPassword,
  },
  data() {
    return {
      isStep: false,
      btnLoading: false,
      successImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/rightIcon.png",
    };
  },
  methods: {
    back() {
      this.$router.push("/forgetPassword");
    },
    btnLoadingChange(val) {
      this.btnLoading = val;
    },
    resetChange() {
      this.isStep = true;
    },
  },
};
</script>
<style lang="less" scoped>
.resetPassword {
  padding: 100px 0;
  .page {
    width: 400px;
    margin: 0 auto;
    .title {
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 30px;
      .iconBack {
        border: 1px solid #7d8695;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .loginBut {
      width: 100%;
      display: block;
      height: 40px;
      margin: 24px auto 12px;
      border: 0 none;
      font-size: 16px;
    }
  }
  .resetTips {
    p {
      font-size: 24px;
      font-weight: bold;
      margin: 15px 0px 24px;
    }
    a {
      color: #027fff;
    }
  }
}
</style>